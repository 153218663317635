import { Button, TextField } from '@mui/material';
import React from 'react';
import './login.component.scss';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import UsuarioModel from '../../models/usuario.model';
import { SnackService } from '../../services/snack.service';
import { LoadingService } from '../../services/loading.service';
import { LoginService, SalvaTokenService } from '../../services/api-usuario.service';
import { useInput } from '../../hooks/useInput.hook';
import { UsuarioEfetuouLoginService } from '../../services/usuario-efetuou-login.service';
import { ModalAcessoService } from '../../services/modal-acesso.service';

function LoginComponent() {

  const { value: emailInputText, bind: bindEmailInputText, setValue: setEmailInputText } = useInput('');
  const { value: senhaInputText, bind: bindSenhaInputText, setValue: setSenhaInputText } = useInput('');
  const [showPassword, setShowPassword] = React.useState<boolean>(false);

  var dto: any = {};

  const Login = () => {

    if (emailInputText === '') {
      SnackService.sendMSG('Informe o e-mail.');
      return;
    }

    if (senhaInputText === '') {
      SnackService.sendMSG('Informe a senha.');
      return;
    }

    dto = {
      user_email: emailInputText,
      user_password: senhaInputText
    };

    LoadingService.sendLoadingAtivo(true);

    LoginService(dto).then((res) => {
      LoadingService.sendLoadingAtivo(false);
      if (res.data?.error) {
        SnackService.sendMSG(res.data?.error);
      } else {
        if (res.data?.errors?.incorrect_password?.length > 0) {
          SnackService.sendMSG('Senha incorreta.');
        } else if (res.data?.errors?.invalid_email?.length > 0) {
          SnackService.sendMSG('Não encontramos cadastro com este e-mail, digitou o e-mail correto?');
        } else if (res.data?.errors?.invalid_username?.length > 0) {
          SnackService.sendMSG('Este usuário não está registrado neste site. Se você não está certo de seu nome de usuário, experimente o endereço de e-mail.');
        } else if (res.data?.dados_us?.data?.user_email?.length > 0) {
          localStorage.setItem("user", JSON.stringify(res.data?.dados_us?.data));
          UsuarioEfetuouLoginService.sendMSG(res.data?.dados_us?.data);
          SalvaToken(res.data?.dados_us?.data?.ID);
          ModalAcessoService.sendMSG(false);
          SnackService.sendMSG('Login efetuado!');
          document.body.classList.remove("login");
        }
      }
    }).catch(err => {
      LoadingService.sendLoadingAtivo(false);
      SnackService.sendMSG('Erro com a conexão.');
      console.log(err);
    });
  }

  const SalvaToken = (user_id) => {

    dto = {
      user_id: user_id,
      user_email: emailInputText,
      token: localStorage.getItem('token')
    };

    SalvaTokenService(dto).then((res) => {
      if (res.data?.error) {
        SnackService.sendMSG(res.data?.error);
      } else {
        
      }
    }).catch(err => {
      SnackService.sendMSG('Erro com a conexão.');
      console.log(err);
    });
  }

  return (
    <div className="login-component">
      <h3>Login</h3>
      <TextField {...bindEmailInputText} label="E-mail" />
      <div className="box-pass">
        <TextField type={showPassword ? "text" : "password"} {...bindSenhaInputText} label="Senha" />
        <Button className="bt-toggle-pass" onClick={() => setShowPassword(!showPassword)}>
          {showPassword ? <VisibilityOff /> : <Visibility />}
        </Button>
      </div>
      <Button onClick={Login} className="bt">Entrar</Button>
    </div>
  );
}

export default LoginComponent;
