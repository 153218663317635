import React, { useEffect } from 'react';
import { useParams, NavLink } from 'react-router-dom';
import CursoModel from '../../models/curso.model';
import { LoadingService } from '../../services/loading.service';
import { SnackService } from '../../services/snack.service';
import './aluno.page.scss';
import { setTimeout } from 'timers';
import { GetAlunoService } from '../../services/api-usuario.service';
import UsuarioModel from '../../models/usuario.model';
import { AccountCircle, Instagram, LinkedIn, Phone } from '@mui/icons-material';
import { Button, CircularProgress } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

function AlunoPage() {

  let { idAluno } = useParams<{ idAluno: string }>();
  const [articleStructuredData, setArticleStructuredData] = React.useState<any>();
  const [infosAluno, setInfosAluno] = React.useState<UsuarioModel>();
  const [progressAluno, setProgressAluno] = React.useState<any[]>();

  useEffect(() => {
    setTimeout(() => {
      GetAluno();
    }, 100);
  }, []);

  useEffect(() => {
    SetMetaHeader();
  }, [infosAluno]);

  useEffect(() => {
    setArticleStructuredData({
      "@context": "https://schema.org",
      "@type": "WatchAction",
      "agent": {
        "@type": "Person",
        "name": infosAluno?.first_name
      },
      "provider": {
        "@type": "Organization",
        "name": "Tutors - Cursos gratuitos",
        "sameAs": "https://tutors.a7apps.com",
      },
    })
  }, []);

  const GetAluno = () => {
    LoadingService.sendLoadingAtivo(true);
    GetAlunoService({ idAluno: idAluno }).then(res => {
      LoadingService.sendLoadingAtivo(false);
      if (res.data.error) {
        SnackService.sendMSG(res.data.error);
      } else {
        setInfosAluno(res.data.dados_us);
        setProgressAluno(res.data.progress);
      }
    }).catch(err => {
      SnackService.sendMSG('Erro com a conexão ao consultar frase na base de dados.');
      LoadingService.sendLoadingAtivo(false);
      console.log(err);
    });
  }

  const SetMetaHeader = () => {
    document.title = infosAluno?.first_name!;
    let metaTagKeywords = document.querySelector('meta[name="keywords"]') as HTMLInputElement | null;
    let metaTagDescription = document.querySelector('meta[name="description"]') as HTMLInputElement | null;

    metaTagKeywords?.setAttribute("content", infosAluno?.first_name!);
    metaTagDescription?.setAttribute("content", infosAluno?.first_name!);
  }

  const GetCalcProgress = (arrProgress: any) => {
    let newArr = JSON.parse(arrProgress);
    let totProgress = 0;
    newArr.map(item => {
      totProgress += item;
    });
    let total100 = newArr.length * 100;
    // console.log('newArr.length', newArr.length);
    // console.log('total100', total100);
    // console.log('totProgress', totProgress);
    return Math.round((100 / (total100 / totProgress)));
  }

  return (
    <>
      <script type="application/ld+json">
        {JSON.stringify(articleStructuredData)}
      </script>
      <section className="aluno-page">
        <div className="container">
          <div className="infos">
            <h1>Perfil do aluno</h1>
            <div className="redes">
              {
                infosAluno?.first_name &&
                <h2>
                  <AccountCircle />
                  {infosAluno.first_name}
                </h2>
              }
              {
                // usuario?.user_email &&
                // <h2>
                //   <Email />
                //   <a href={"mailto:" + usuario.user_email}>{usuario.user_email}</a>
                // </h2>
              }
              {
                infosAluno?.telefone &&
                <h2>
                  <Phone />
                  <a href={'https://wa.me/55' + ((infosAluno.telefone).split(' ').join('')).split('-').join('')} target="_blank">
                    {infosAluno.telefone}
                  </a>
                </h2>
              }
              {
                infosAluno?.linkedin &&
                <h2>
                  <LinkedIn />
                  <a href={'https://br.linkedin.com/in/' + infosAluno.linkedin} target="_blank">
                    {infosAluno.linkedin}
                  </a>
                </h2>
              }
              {
                infosAluno?.instagram &&
                <h2>
                  <Instagram />
                  <a href={'https://www.instagram.com/' + infosAluno.instagram} target="_blank">
                    {infosAluno.instagram}
                  </a>
                </h2>
              }
            </div>
            {
              infosAluno?.foto &&
              <i style={{ backgroundImage: 'url(' + infosAluno.foto + ')' }}></i>
            }
          </div>
        </div>
      </section>
      <section className="aluno-page progress-sec">
        <div className="container">
          <div className="itens-progress">
            <h2>Cursos realizados</h2>
            {
              progressAluno && progressAluno?.map((item, indexCurso) => (
                <div key={indexCurso} className="item-progress flex flex-cv">
                  <NavLink exact={true} to={'/cursos-gratuitos/' + item.curso.alias}>
                    <b style={{ backgroundImage: 'url(' + item.curso.thumb + ')' }}>
                      {
                        GetCalcProgress(item.progress_videos) >= 100 &&
                        <CheckCircleIcon className='concluido' />
                      }
                      <CircularProgress variant="determinate" value={GetCalcProgress(item.progress_videos)} />
                    </b>
                  </NavLink>
                  <div className="txt">
                    <NavLink exact={true} to={'/cursos-gratuitos/' + item.curso.alias}>
                      <Button>{item.curso.titulo}</Button>
                    </NavLink>
                    <h3>{item.curso.breve_descricao}</h3>
                    <h3 className='perc'>
                      {GetCalcProgress(item.progress_videos)}% do curso concluído.
                    </h3>
                  </div>
                </div>
              ))
            }
          </div>
        </div>
      </section>
    </>
  );
}

export default AlunoPage;
