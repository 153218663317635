import { CircularProgress } from '@mui/material';
import React, { useEffect } from 'react';
import { LoadingService } from '../../services/loading.service';
import './loading.component.scss';

function LoadingComponent() {

  const [isLoadingActive, setIsLoadingActive] = React.useState<boolean | unknown>(false);

  useEffect(() => {
    LoadingService.getLoadingAtivo().subscribe((newsStateLoading) => {
      setIsLoadingActive(newsStateLoading);
    });
  }, []);

  return (
    <div className={'loading-component flex flex-ch flex-cv ' + (isLoadingActive && 'ativo')}>
      <CircularProgress />
    </div>
  );
}

export default LoadingComponent;
