
import EnvironmentModel from "./environment.model"

let env: EnvironmentModel = {
    env: "dev",
    serverUrl: "http://localhost:8888/tutors/back/apis",
    pagesUrl: "http://localhost:8888/tutors/back",
};

const getEnv = (urlAtual: string) => {
    if ((urlAtual).indexOf('hml') >= 0) { // hml
        env = {
            env: "hml",
            serverUrl: "https://tutors.a7apps.com/back/apis",
            pagesUrl: "https://tutors.a7apps.com/back",
        };
    } else if ((urlAtual).indexOf('tutors.a7apps') >= 0) { // prod
        env = {
            env: "prd",
            serverUrl: "https://tutors.a7apps.com/back/apis",
            pagesUrl: "https://tutors.a7apps.com/back",
        };
    } else if ((urlAtual).indexOf('localhost') >= 0) { // local
        env = {
            env: "local",
            serverUrl: "http://localhost:8888/tutors/back/apis",
            pagesUrl: "http://localhost:8888/tutors/back",
        };
    }
    
    return env;
}

export const environment: EnvironmentModel = getEnv(window.location.host);
