import axios from "axios";
import { environment } from "../environments/environment";
import AvaliacaoModel from "../models/avaliacao.model";
import FavoritoModel from "../models/favorito.model";

export const api = axios.create({
    baseURL: environment.serverUrl
});

export async function SalvarFavoritoService(dto: FavoritoModel) {
    let url = '/salvar-favoritos';
    return await api.post(url, dto, {});
}

export async function ListarFavoritosService(dto: { user_id: number}) {
    let url = '/listar-favoritos';
    return await api.post(url, dto, {});
}

export async function DeleteFavoritosService(dto: { id_favorito: number }) {
    let url = '/delete-favoritos';
    return await api.post(url, dto, {});
}
