import { Button } from '@mui/material';
import React from 'react';
import { useHistory } from 'react-router-dom';
import CursoModel from '../../models/curso.model';
import VisibilityIcon from '@mui/icons-material/Visibility';
import StarsIcon from '@mui/icons-material/Stars';
import './card-favorito.component.scss';
import { getAuthUser } from '../../services/auth';
import UsuarioModel from '../../models/usuario.model';
import { SnackService } from '../../services/snack.service';
import { LoadingService } from '../../services/loading.service';
import { DeleteFavoritosService, SalvarFavoritoService } from '../../services/api-favoritos.service';
import FavoritoModel from '../../models/favorito.model';

export type CardFavoritoComponentProps = {
  itemFavoritos: FavoritoModel;
  usuario: UsuarioModel;
  deleteMe: (id: number) => void;
}

function CardFavoritoComponent(props: CardFavoritoComponentProps) {

  let history = useHistory();

  const AcessarCurso = (alias?: string) => {
    history?.push({
      pathname: `/cursos-gratuitos/${alias}`
    })
  }

  const RemoverFavorito = (favoritoID: number) => {
    let usuario: UsuarioModel = getAuthUser();

    if (!usuario) {
      SnackService.sendMSG('Faça login para favoritar cursos.');
      return;
    }

    LoadingService.sendLoadingAtivo(true);
    let dto = {
      id_favorito: favoritoID
    }
    DeleteFavoritosService(dto).then(res => {
      LoadingService.sendLoadingAtivo(false);
      if (res.data.error) {
        SnackService.sendMSG(res.data.error);
      } else {
        SnackService.sendMSG(res.data);
        props.deleteMe(favoritoID);
      }
    }).catch(err => {
      SnackService.sendMSG('Erro com a conexão ao consultar frase na base de dados.');
      LoadingService.sendLoadingAtivo(false);
      console.log(err);
    });
  }

  return (
    <>
      <div className="card-favorito">
        <img src={props.itemFavoritos.thumb} alt={props.itemFavoritos.titulo_curso} />
        <div className="txt">
          <h3>{props.itemFavoritos.titulo_curso}</h3>
          <h4>{props.itemFavoritos.breve_descricao}</h4>
          <div className="bts">
            <Button className='bt' onClick={() => AcessarCurso(props.itemFavoritos.url_curso)}>
              <VisibilityIcon />
              Acessar curso
            </Button>
            <Button className='bt favorito' onClick={() => RemoverFavorito(props.itemFavoritos.id)}>
              <StarsIcon />
              Remover
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}

export default CardFavoritoComponent;
