
import React from 'react';
import CookieConsent, { Cookies } from "react-cookie-consent";
import { BrowserRouter, Switch, Route, NavLink } from 'react-router-dom';
import HeaderComponent from './components/header/header.component';
import NotFoundPage from './pages/not-found/not-found.page';
import HomePage from './pages/home/home.page';
import LoadingComponent from './components/loading/loading.component';
import SnackComponent from './components/snack/snack.component';
import CursoPage from './pages/curso/curso.page';
import ModalAcessoComponent from './components/modal-acesso/modal-acesso.component';
import ProtectedRoute from './protected-route';
import { MinhaContaPage } from './pages/minha-conta/minha-conta.page';
import { CartaoVirtualComponent } from './components/cartao-virtual/cartao-virtual.component';
import PoliticaPage from './pages/politica/politica.page';
import { Button } from '@mui/material';
import FavoritosPage from './pages/favoritos/favoritos.page';
import AlunoPage from './pages/aluno/aluno.page';
import CategoriaPage from './pages/categoria/categoria.page';
import FooterComponent from './components/footer/footer.component';

function Routes() {

    return (
        <BrowserRouter>
            <HeaderComponent />
            <Switch>
                <Route exact path="/" component={HomePage} />
                <Route exact path="/cursos-gratuitos/:AliasCurso" component={CursoPage} />
                <Route exact path="/categorias/:AliasCategoria" component={CategoriaPage} />
                <Route exact path="/aluno/:idAluno" component={AlunoPage} />
                <Route exact path="/politica-de-privacidade" component={PoliticaPage} />
                <ProtectedRoute
                    exact={true}
                    path={'/minha-conta'}
                    component={MinhaContaPage}
                />
                <ProtectedRoute
                    exact={true}
                    path={'/favoritos'}
                    component={FavoritosPage}
                />
                <Route component={NotFoundPage} />
            </Switch>
            <FooterComponent />
            <CartaoVirtualComponent />
            <ModalAcessoComponent />
            <SnackComponent />
            <LoadingComponent />
            <CookieConsent
                location="bottom"
                buttonText="Aceitar"
                cookieName="CookieConsent"
                expires={150}
            >
                Este site usa cookies para melhorar a experiência do usuário.
                <NavLink exact={true} to="/politica-de-privacidade" activeClassName="active">
                    <Button>Política de Privacidade</Button>
                </NavLink>
            </CookieConsent>
        </BrowserRouter>
    )
}

export default Routes;