import React, { useEffect, useLayoutEffect } from 'react';
import { useHistory } from 'react-router-dom';
import CardCursoComponent from '../../components/card-curso/card-curso.component';
import CardFavoritoComponent from '../../components/card-favorito/card-favorito.component';
import CursoModel from '../../models/curso.model';
import FavoritoModel from '../../models/favorito.model';
import UsuarioModel from '../../models/usuario.model';
import { ListarFavoritosService } from '../../services/api-favoritos.service';
import { getAuthUser } from '../../services/auth';
import { LoadingService } from '../../services/loading.service';
import { SnackService } from '../../services/snack.service';
import './favoritos.page.scss';

export default function FavoritosPage() {
  const [listFavoritos, setListFavoritos] = React.useState<Array<FavoritoModel>>([]);
  var usuario: any = getAuthUser();

  useLayoutEffect(() => {
    setTimeout(() => {
      ListarFavoritos();
    }, 500);
  }, []);

  const ListarFavoritos = () => {
    LoadingService.sendLoadingAtivo(true);
    ListarFavoritosService({ user_id: usuario.ID }).then(res => {
      LoadingService.sendLoadingAtivo(false);
      if (res.data.error) {
        SnackService.sendMSG(res.data.error);
      } else {
        setListFavoritos(res.data);
      }
    }).catch(err => {
      SnackService.sendMSG('Erro com a conexão ao consultar frase na base de dados.');
      LoadingService.sendLoadingAtivo(false);
      console.log(err);
    });
  }

  const DeleteItemFavoritos = (id: number) => {
    const newList = listFavoritos.filter((item) => item.id !== id);
    setListFavoritos(newList);
  }


  return (
    <>
      <section className="favoritos">
        <div className="container">
          <h1>Cursos favoritos</h1>
          <div className="cursos-gratuitos flex flex-ch flex-wr">
            {
              listFavoritos.length ? listFavoritos.map((item, indexCurso) => (
                <CardFavoritoComponent deleteMe={(id) => { DeleteItemFavoritos(id) }} usuario={usuario} itemFavoritos={item} key={indexCurso} />
              )) : <span className='msg-0-itens'>Nenhum item encontrado! Clique no botão "favoritar curso" na home.</span>
            }
          </div>
        </div>
      </section>
    </>
  );
}