import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import CardCursoComponent from '../../components/card-curso/card-curso.component';
import CursoModel from '../../models/curso.model';
import UsuarioModel from '../../models/usuario.model';
import { BuscarCursosService, ListarCursosPorCategoriaService, ListarCursosService } from '../../services/api-cursos.service';
import { getAuthUser } from '../../services/auth';
import { LoadingService } from '../../services/loading.service';
import { SnackService } from '../../services/snack.service';
import './categoria.page.scss';
import { Button, TextField } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useInput } from '../../hooks/useInput.hook';
import SearchIcon from '@mui/icons-material/Search';

export default function CategoriaPage() {
  const [listCursos, setListCursos] = React.useState<Array<CursoModel>>([]);
  const [articleStructuredData, setArticleStructuredData] = React.useState<any>();
  const [listIDs, setListIDs] = React.useState<Array<number>>([]);
  const [acabouListCursos, setAcabouListCursos] = React.useState<boolean>(false);
  const { value: buscaInputText, bind: bindBuscaInputText, setValue: setBuscaInputText } = useInput('');
  const [isLoadingActive, setIsLoadingActive] = React.useState<boolean | unknown>(false);
  const [limpaFiltro, setLimpaFiltro] = React.useState<boolean>(false);
  var usuario: UsuarioModel = getAuthUser();
  let { AliasCategoria } = useParams<{ AliasCategoria: string }>();

  useLayoutEffect(() => {
    LimparFiltro();

    setTimeout(() => {
      ListarCursos();
    }, 500);

    LoadingService.getLoadingAtivo().subscribe((newsStateLoading) => {
      setIsLoadingActive(newsStateLoading);
    });
  }, [AliasCategoria]);

  useEffect(() => {
    setArticleStructuredData({
      "@context": "https://schema.org",
      "@type": "ItemList",
      "itemListElement": MontaItemListElement()
    })
  }, [listCursos]);

  const ListarCursos = () => {
    LoadingService.sendLoadingAtivo(true);
    ListarCursosPorCategoriaService({ aliasCat: AliasCategoria, idsCursos: listIDs }).then(res => {
      LoadingService.sendLoadingAtivo(false);
      if (res.data.error) {
        SnackService.sendMSG(res.data.error);
      } else {
        setListIDs(listIDs.concat(res.data.idsArr));
        setListCursos(listCursos.concat(res.data.cursosArr));
        if (res.data.cursosArr.length < 8) {
          setAcabouListCursos(true);
        }
      }
    }).catch(err => {
      SnackService.sendMSG('Erro com a conexão ao consultar cursos na base de dados.');
      LoadingService.sendLoadingAtivo(false);
      console.log(err);
    });
  }

  const BuscarCursos = () => {

    if (!buscaInputText) {
      SnackService.sendMSG('Digite o nome do curso no campo "BUSCAR CURSO..."');
      return;
    }

    LoadingService.sendLoadingAtivo(true);
    setLimpaFiltro(true);

    BuscarCursosService({ busca: buscaInputText }).then(res => {
      LoadingService.sendLoadingAtivo(false);
      if (res.data.error) {
        SnackService.sendMSG(res.data.error);
      } else {
        setListCursos(res.data.cursosArr);
        if (res.data.cursosArr.length < 8) {
          setAcabouListCursos(true);
        }
      }
    }).catch(err => {
      SnackService.sendMSG('Erro com a conexão ao consultar cursos na base de dados.');
      LoadingService.sendLoadingAtivo(false);
      console.log(err);
    });
  }

  const BuscarCursosEnter = (event) => {
    if (event.key === 'Enter') {
      BuscarCursos();
    }
  }

  const MontaItemListElement = () => {
    let arrItemList: any = [];
    listCursos.map((item: CursoModel, index: number) => {
      arrItemList.push({
        "@type": "ListItem",
        "position": index + 1,
        "item": {
          "@type": "Course",
          "url": 'http://tutors.a7apps.com/cursos-gratuitos/' + item.alias,
          "name": item.titulo,
          "image": item.thumb,
          "provider": {
            "@type": "Organization",
            "name": "Tutors - Cursos gratuitos",
            "sameAs": "https://tutors.a7apps.com",
          },
        }
      });
    });

    return arrItemList;
  }

  const LimparFiltro = () => {
    setLimpaFiltro(false);
    setAcabouListCursos(false);
    listCursos.length = 0;
    listIDs.length = 0;
    setBuscaInputText('');
    ListarCursos();
  }

  return (
    <>
      <script type="application/ld+json">
        {JSON.stringify(articleStructuredData)}
      </script>
      <section className="home">
        <div className="container">
          <div className="box-topo flex flex-sp flex-cv flex-wr">
            <h1>Cursos gratuitos</h1>
            <div className="box-busca flex flex-cv">
              <div className="box-input">
                <TextField {...bindBuscaInputText} label="Buscar curso..." onKeyPress={BuscarCursosEnter} />
                <Button onClick={BuscarCursos}>
                  <SearchIcon />
                </Button>
              </div>
              {
                limpaFiltro &&
                <Button className='bt' onClick={LimparFiltro}>
                  Limpar filtro
                </Button>
              }
            </div>
          </div>
          <div className="cursos-gratuitos flex flex-ch flex-wr">
            {
              listCursos ? listCursos.map((item, indexCurso) => (
                <CardCursoComponent usuario={usuario} itemCurso={item} key={indexCurso} />
              )) : <span>Nenhum item encontrado.</span>
            }
          </div>
          {
            (!acabouListCursos && !isLoadingActive) &&
            <div className="box-bt-mais flex flex-ch">
              <Button className='bt' onClick={ListarCursos}>
                <AddIcon />
                Carregar mais cursos...
              </Button>
            </div>
          }
        </div>
      </section>
    </>
  );
}