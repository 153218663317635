import React from 'react';
import { Button, TextField } from '@mui/material';
import './cadastro.component.scss';
import UsuarioModel from '../../models/usuario.model';
import { SnackService } from '../../services/snack.service';
import { LoadingService } from '../../services/loading.service';
import { CadastrarService } from '../../services/api-usuario.service';
import { useInput } from '../../hooks/useInput.hook';

function CadastroComponent() {

  const { value: emailInputText, bind: bindEmailInputText, setValue: setEmailInputText } = useInput('');
  const { value: nomeCompletoInputText, bind: bindNomeCompletoInputText, setValue: setNomeCompletoInputText } = useInput('');
  const { value: dealerInputText, bind: bindDealerInputText, setValue: setDealerInputText } = useInput('');
  const { value: cidadeInputText, bind: bindCidadeInputText, setValue: setCidadeInputText } = useInput('');
  const { value: estadoInputText, bind: bindEstadoInputText, setValue: setEstadoInputText } = useInput('');

  var dto: any;

  const Cadastrar = (e: any) => {
    e.stopPropagation();

    if (!emailInputText) {
      SnackService.sendMSG('O campo e-mail é obrigatório');
      return;
    } else if (emailInputText.indexOf('@') == -1 || emailInputText.indexOf('.') == -1) {
      SnackService.sendMSG('Digite um e-mail válido');
      return;
    } else if (!nomeCompletoInputText) {
      SnackService.sendMSG('O campo nome é obrigatório');
      return;
    }

    dto = {
      user_email: emailInputText,
      first_name: nomeCompletoInputText,
      dealer: '',
      cidade: cidadeInputText,
      estado: estadoInputText
    };

    LoadingService.sendLoadingAtivo(true);

    CadastrarService(dto).then((res) => {
      LoadingService.sendLoadingAtivo(false);
      if (res.data?.error) {
        SnackService.sendMSG(res.data?.error);
      } else {
        if (res?.data?.email_exists) {
          SnackService.sendMSG(res?.data?.email_exists);
        } else if (res?.data?.random_password) {
          SnackService.sendMSG('Usuário cadastrado, enviamos um e-mail para ' + dto.user_email + ' com a sua senha. *Se não receber imediatamente, verifique em sua caixa de spam.', 'center', 30000);
          LimparForm();
        }
      }
    }).catch(err => {
      LoadingService.sendLoadingAtivo(false);
      SnackService.sendMSG('Erro com a conexão.');
      console.log(err);
    });
  }

  const LimparForm = () => {
    setEmailInputText('');
    setNomeCompletoInputText('');
    setDealerInputText('');
    setCidadeInputText('');
    setEstadoInputText('');
  }

  return (
    <div className="cadastro-component">
      <h3>Cadastre-se</h3>
      <TextField {...bindEmailInputText} label="E-mail" />
      <small className='msg-email'>Forneça seu e-mail principal para que possamos enviar a senha.</small>
      <TextField {...bindNomeCompletoInputText} label="Nome completo" />
      <TextField {...bindCidadeInputText} label="Cidade" />
      <TextField {...bindEstadoInputText} label="Estado" />
      <Button onClick={Cadastrar} className="bt">Enviar</Button>
    </div>
  );
}

export default CadastroComponent;
