import axios from "axios";
import { environment } from "../environments/environment";

const config: any = {
    //headers: {}
};

export const api = axios.create({
    baseURL: environment.serverUrl
});

export async function UploadImagemMinhaContaService(formData: any, user_id: any) {
    let url = '/upload-imagem-minha-conta';
    return await api.post(url, formData, config);
}
