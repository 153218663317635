import React from 'react';
import './footer.component.scss';

export default function FooterComponent() {

  return (
    <footer className='flex flex-ch'>
      <strong>Fale conosco:</strong> <a href="mailto:contato@tutors.a7apps.com">contato@tutors.a7apps.com</a>
    </footer>
  );
}
