import axios from "axios";
import { environment } from "../environments/environment";
import ProgressVideoModel from "../models/progress.model";

const config: any = {
    //headers: {}
};

export const api = axios.create({
    //baseURL: environment.apiMockUrl
    baseURL: environment.serverUrl
});

export async function InsereProgressVideosService(dto: ProgressVideoModel) {
    let url = '/inserir-progress-videos';
    return await api.post(url, dto, config);
}

export async function GetProgressVideosService(dto: ProgressVideoModel) {
    let url = '/get-progress-videos';
    return await api.post(url, dto, config);
}
