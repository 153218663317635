import { AccountCircle, Email, Instagram, LinkedIn, Phone } from '@mui/icons-material';
import { Close } from '@mui/icons-material';
import { Button } from '@mui/material';
import React, { useEffect } from 'react';
import UsuarioModel from '../../models/usuario.model';
import { CartaoVirtualService } from '../../services/cartao-virtual.service';
import './cartao-virtual.component.scss';

export function CartaoVirtualComponent() {

  const [usuario, setUsuario] = React.useState<UsuarioModel>();
  const [isCartaoAtivo, setIsCartaoAtivo] = React.useState<boolean | unknown>(false);

  useEffect(() => {
    CartaoVirtualService.getCartaoVirtual().subscribe((usuarioService) => {
      setUsuario(usuarioService);
      setIsCartaoAtivo(true);
    });
  }, []);

  return (
    <section className={'cartao-virtual-component flex flex-ch flex-cv ' + (isCartaoAtivo && 'ativo')}>
      <div className="box">
        <div className="infos">
          <h1>Cartão virtual</h1>
          {
            usuario?.first_name &&
            <h2>
              <AccountCircle />
              {usuario.first_name}
            </h2>
          }
          {
            // usuario?.user_email &&
            // <h2>
            //   <Email />
            //   <a href={"mailto:" + usuario.user_email}>{usuario.user_email}</a>
            // </h2>
          }
          {
            usuario?.telefone &&
            <h2>
              <Phone />
              <a href={'https://wa.me/55' + ((usuario.telefone).split(' ').join('')).split('-').join('')} target="_blank">
                {usuario.telefone}
              </a>
            </h2>
          }
          {
            usuario?.linkedin &&
            <h2>
              <LinkedIn />
              <a href={'https://br.linkedin.com/in/' + usuario.linkedin} target="_blank">
                {usuario.linkedin}
              </a>
            </h2>
          }
          {
            usuario?.instagram &&
            <h2>
              <Instagram />
              <a href={'https://www.instagram.com/' + usuario.instagram} target="_blank">
                {usuario.instagram}
              </a>
            </h2>
          }
          {
            usuario?.foto &&
            <i style={{ backgroundImage: 'url(' + usuario.foto + ')' }}></i>
          }
        </div>
        <Button className="fechar" onClick={() => setIsCartaoAtivo(false)}>
          <Close />
        </Button>
      </div>
    </section>
  );
}
