import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import React, { useEffect } from 'react';
import { SnackService } from '../../services/snack.service';
import './snack.component.scss';

function SnackComponent() {

  const [openSnack, setIsOpenSnackbar] = React.useState<boolean>(false);
  const [msgSnakbar, setMsgSnakbar] = React.useState<any>('');
  const [verticalSnakbar, setVerticalSnakbar] = React.useState<"bottom" | "top">('bottom');
  const [timeSnakbar, setTimeSnakbar] = React.useState<number>(6000);

  useEffect(() => {
    SnackService.getMSG().subscribe((props: any) => {
      setMsgSnakbar(props.msg);
      setVerticalSnakbar(props.vertical ? props.vertical : 'bottom');
      setTimeSnakbar(props.timeSnak ? props.timeSnak : 6000);
      setTimeout(() => {
        setIsOpenSnackbar(true);
      }, 200);
    });
  }, []);

  const handleCloseSnack = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setIsOpenSnackbar(false);
  };

  return (
    <Snackbar
      anchorOrigin={{
        vertical: verticalSnakbar,
        horizontal: 'center',
      }}
      open={openSnack}
      autoHideDuration={timeSnakbar}
      onClose={handleCloseSnack}
      message={msgSnakbar}
      className="vermelho"
      action={
        <IconButton onClick={handleCloseSnack}>
          <CloseIcon fontSize="small" />
        </IconButton>
      }
    />
  );
}

export default SnackComponent;
