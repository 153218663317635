import axios from "axios";
import { environment } from "../environments/environment";

const config: any = {
    //headers: {}
};

export const api = axios.create({
    //baseURL: environment.apiMockUrl
    baseURL: environment.serverUrl
});

export async function ListarCursosService(dto: { user_email: string, idsCursos: number[] }) {
    let url = '/get-cursos';
    return await api.post(url, dto, config);
}

export async function ListarCursosPorCategoriaService(dto: { aliasCat: string, idsCursos: number[] }) {
    let url = '/list-cursos-por-categoria';
    return await api.post(url, dto, config);
}

export async function ListarCategoriasCursosService(dto: { id: number }) {
    let url = '/get-categorias';
    return await api.post(url, dto, config);
}

export async function BuscarCursosService(dto: { busca: string }) {
    let url = '/get-busca-cursos';
    return await api.post(url, dto, config);
}

export async function CursoService(dto: { alias: string }) {
    let url = '/get-curso';
    return await api.post(url, dto, config);
}
