import { Button, TextField, Tooltip } from '@mui/material';
import axios from 'axios';
import CommentIcon from '@mui/icons-material/Comment';
import ReplyIcon from '@mui/icons-material/Reply';
import React, { useEffect } from 'react';
import { useInput } from '../../hooks/useInput.hook';
import UsuarioModel from '../../models/usuario.model';
import { InserirComentarioService, ListarComentariosService } from '../../services/api-comentarios.service';
import { GetUserCartaoVirtualService } from '../../services/api-usuario.service';
import { getAuthUser } from '../../services/auth';
import { CartaoVirtualService } from '../../services/cartao-virtual.service';
import { LoadingService } from '../../services/loading.service';
import { SnackService } from '../../services/snack.service';
import './comentarios.component.scss';
import ComentariosModel from './comentarios.model';
import { CommentSection } from 'react-comments-section';
import 'react-comments-section/dist/index.css';


export type ComentaiosComponentProps = {
  palavrasModeradasADM?: Array<string>;
  idCurso: number;
}

function ComentaiosComponent(props: ComentaiosComponentProps) {

  const [listComentarios, setListComentarios] = React.useState<Array<ComentariosModel>>([]);
  const [isFormActive, setIsFormActive] = React.useState<boolean>(false);
  const [usuarioReply, setUsuarioReply] = React.useState<string | undefined>('');
  const [usuarioReplyID, setUsuarioReplyID] = React.useState<number | undefined>();
  const { value: msgInputText, bind: bindMSGInputText, setValue: setMSGInputText } = useInput('');

  var dto: ComentariosModel = {};
  var user_dto: any;
  var usuario: any = getAuthUser();

  const sourceApiComentarios = axios.CancelToken.source();
  const [commentData, setCommentData] = React.useState<any>();

  useEffect(() => {
    ListarComentarios(props.idCurso, 50, 0);

    return function cleanup() {
      sourceApiComentarios.cancel("CancelComentario");
    };
  }, []);

  useEffect(() => {
  }, [msgInputText]);

  const ListarComentarios = (id_post: number | undefined, qtd_posts: number, count_posts: number) => {

    dto.id = id_post;
    dto.id_curso = props.idCurso;
    dto.qtd = qtd_posts;
    dto.count_posts = count_posts;
    dto.cancel = sourceApiComentarios.token;

    LoadingService.sendLoadingAtivo(true);

    ListarComentariosService(dto).then((res) => {
      LoadingService.sendLoadingAtivo(false);
      if (res.data.error) {
        SnackService.sendMSG(res.data.error);
      } else {
        console.log(res.data.comentarios, '---res.data.comentarios');
        let itens: ComentariosModel[] = res.data.comentarios;
        //setListComentarios(itens);
        setCommentData(res.data.comentarios);
      }
    }).catch(err => {
      LoadingService.sendLoadingAtivo(false);
      if (err.message === 'CancelComentario') {

      } else {
        SnackService.sendMSG('Erro com a conexão ao listar comentários.');
        console.log(err);
      }
    });
  }

  const InserirComentario = (_comment: any) => {

    if (!usuario) {
      SnackService.sendMSG('Para comentar, efetue o login clicando em "ACESSAR"!');
      return;
    }

    let msgUSU_moderada = msgInputText.toLocaleLowerCase();
    let palavrasModeradas = props.palavrasModeradasADM;

    palavrasModeradas?.map(item_palavra_moderada => {
      msgUSU_moderada = (msgUSU_moderada).replaceAll(item_palavra_moderada, '&%$#@');
    });

    console.log(msgInputText.indexOf(usuarioReply) <= -1, '--if');
    console.log(msgInputText, '--msgInputText');
    console.log(usuarioReplyID, '--usuarioReplyID');

    if (msgInputText.indexOf(usuarioReply) <= -1) {
      setUsuarioReplyID(undefined);
    }

    dto = {
      id_comentario_pai: _comment.parentOfRepliedCommentId ? _comment.parentOfRepliedCommentId : _comment.repliedToCommentId,
      id: _comment.userId,
      id_curso: props.idCurso,
      comentario: _comment.text,
      usuario: _comment.fullName,
      foto: _comment.avatarUrl
    };
    setIsFormActive(false);
    LoadingService.sendLoadingAtivo(true);
    InserirComentarioService(dto).then((res) => {
      LoadingService.sendLoadingAtivo(false);
      if (res.data.error) {
        SnackService.sendMSG(res.data.error);
      } else {
        setMSGInputText('');
        ListarComentarios(props.idCurso, 50, 0);
      }
    }).catch(err => {
      LoadingService.sendLoadingAtivo(false);
      SnackService.sendMSG('Erro com a conexão.');
      console.log(err);
    });
  }

  const CartaoVirtual = (user_id?: number) => {

    user_dto = {
      ID: user_id
    }
    LoadingService.sendLoadingAtivo(true);
    GetUserCartaoVirtualService(user_dto).then((res) => {
      LoadingService.sendLoadingAtivo(false);
      if (res.data.error) {
        SnackService.sendMSG(res.data.error);
      } else {
        CartaoVirtualService.sendCartaoVirtual(res.data.dados_us);
      }
    }).catch(err => {
      LoadingService.sendLoadingAtivo(false);
      SnackService.sendMSG('Erro com a conexão.');
      console.log(err);
    });
  }

  const handleKeywordKeyPress = (e: any) => {
    //console.log(msgInputText + ' < msgInputText');
    if (msgInputText) {
      setIsFormActive(true);
      // if (e.key == 'Enter') {
      //   InserirComentario();
      // }
    } else {
      setIsFormActive(false);
    }
  };

  const Responder = (usuarioReplyBT: string | undefined, usuarioReplyBTid: number | undefined) => {
    setMSGInputText('@' + usuarioReplyBT);
    setUsuarioReply(usuarioReplyBT);
    setUsuarioReplyID(usuarioReplyBTid);
  };

  const onSubmitAction = (data: any) => {
    console.log('this comment was posted!', data);
    InserirComentario(data);
  }

  const onReplyAction = (data: any) => {
    console.log('this comment was replyed!', data);
    InserirComentario(data);
  }

  const onEditAction = (data: any) => {
    console.log('this comment was edited!', data);
  }

  return (
    <>
      {/* <div className="comentarios">
        <h2 className='flex flex-cv flex-sp'>
          <span>Deixe um comentário</span>
          <CommentIcon />
        </h2>
        <div className={"form flex flex-sp flex-cv " + (isFormActive && ' ativo')}>
          <TextField onKeyUp={handleKeywordKeyPress} {...bindMSGInputText} label="Envie seu comentário" />
          <Button className="bt" onClick={() => InserirComentario()}>
            Enviar
          </Button>
        </div>
        <div className="box">
          {
            listComentarios ? listComentarios?.map((item, indexItem) => (
              <div className="item" key={indexItem}>
                <Tooltip title="Ver cartão virtual">
                  <h3 onClick={() => CartaoVirtual(item.id_usuario)} className="flex flex-cv">
                    {
                      item?.foto &&
                      <i style={{ backgroundImage: 'url(' + item.foto + ')' }}></i>
                    }
                    {item.usuario?.split('@')[0]}
                  </h3>
                </Tooltip>
                <div className="txt">
                  {item.comentario}
                </div>
                <Button className='bt branco' onClick={() => Responder(item.usuario?.split('@')[0], item.id)}><ReplyIcon /> Responder</Button>
                <div className={"form flex flex-sp flex-cv " + (isFormActive && ' ativo')}>
                  <TextField onKeyUp={handleKeywordKeyPress} {...bindMSGInputText} label="Envie seu comentário" />
                  <Button className="bt" onClick={() => InserirComentario()}>
                    Enviar
                  </Button>
                </div>
                {
                  item.parents &&
                  <div className="respostas">
                    {
                      item.parents.map((item, indexItem) => (
                        <div className="item" key={indexItem}>
                          <h4>Respostas:</h4>
                          <Tooltip title="Ver cartão virtual">
                            <h3 onClick={() => CartaoVirtual(item.id_usuario)} className="flex flex-cv">
                              {
                                item?.foto &&
                                <i style={{ backgroundImage: 'url(' + item.foto + ')' }}></i>
                              }
                              {item.usuario?.split('@')[0]}
                            </h3>
                          </Tooltip>
                          <div className="txt">
                            {item.comentario}
                          </div>
                        </div>
                      ))
                    }
                  </div>
                }
              </div>
            )) : <span>Seja o primeiro a comentar!</span>
          }
        </div>
      </div> */}
      <CommentSection
        currentUser={usuario ? {
          currentUserId: usuario.ID,
          currentUserImg: usuario.foto,
          currentUserProfile: '/aluno/' + usuario.ID,
          currentUserFullName: usuario.first_name
        } : null}
        logIn={{
          loginLink: 'http://localhost:3001/',
          signupLink: 'http://localhost:3001/'
        }}
        commentData={commentData}
        currentData={(data: any) => {
          console.log('curent data', data)
        }}
        onSubmitAction={(data: any) => onSubmitAction(data)}
        onReplyAction={(data: any) => onReplyAction(data)}
        onEditAction={(data: any) => onEditAction(data)}
      />
    </>
  );
}

export default ComentaiosComponent;
