import { NavLink, useHistory } from "react-router-dom";
import Button from '@mui/material/Button';
import Logo from '../../assets/logo-tutors.svg';
import qrCode from '../../assets/qr-code.png';
import './header.component.scss';
import { ModalAcessoService } from "../../services/modal-acesso.service";
import React, { useEffect } from "react";
import { UsuarioEfetuouLogOutService, UsuarioEfetuouLoginService } from "../../services/usuario-efetuou-login.service";
import UsuarioModel from "../../models/usuario.model";
import { getAuthUser, isAuhenticated } from "../../services/auth";
import { AccountCircle } from "@mui/icons-material";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import CategoriaModel from "../../models/categoria.model";
import { LoadingService } from "../../services/loading.service";
import { ListarCategoriasCursosService } from "../../services/api-cursos.service";
import { SnackService } from "../../services/snack.service";
import MenuIcon from '@mui/icons-material/Menu';
import FreeBreakfastIcon from '@mui/icons-material/FreeBreakfast';
import CloseIcon from '@mui/icons-material/Close';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

function HeaderComponent() {

  let history = useHistory();
  let isApp = localStorage.getItem('appParam');

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openMenuUS = Boolean(anchorEl);
  const clickIcoUS = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const [anchorElCat, setAnchorElCat] = React.useState<null | HTMLElement>(null);
  const openMenuCat = Boolean(anchorElCat);
  const clickCategorias = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElCat(event.currentTarget);
  };

  const [anchorElBurger, setAnchorElBurger] = React.useState<null | HTMLElement>(null);
  const openMenuBurger = Boolean(anchorElBurger);
  const clickBurger = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElBurger(event.currentTarget);
  };

  const [usuarioLogado, setUsuarioLogado] = React.useState<UsuarioModel>();
  const [usuario, setUsuario] = React.useState<UsuarioModel>(getAuthUser());
  const [foto, setFoto] = React.useState<string | undefined>(usuario?.foto);
  const [categorias, setCategorias] = React.useState<CategoriaModel[]>();

  const [modalAtivo, setModalAtivo] = React.useState<boolean>(false);

  useEffect(() => {
    ListarCategorias();

    UsuarioEfetuouLoginService.getMSG().subscribe((usuarioService: any) => {
      setUsuarioLogado(usuarioService);
    });

    UsuarioEfetuouLogOutService.getMSG().subscribe((usLogout: any) => {
      if (usLogout == true) {
        Sair();
      }
    });

    let usLogado = localStorage.getItem("user");
    if (usLogado) {
      setUsuarioLogado(JSON.parse(usLogado));
    }
  }, []);

  const Acessar = () => {
    ModalAcessoService.sendMSG(true);
  }

  const Sair = () => {
    localStorage.removeItem('user');
    setAnchorEl(null);
    setUsuarioLogado(undefined);
    history?.push({
      pathname: '/'
    })
  }

  const MinhaConta = () => {
    history?.push({
      pathname: '/minha-conta'
    })
  }

  const MeusFavoritos = () => {
    history?.push({
      pathname: '/favoritos'
    })
  }

  const GoToCategorias = (linkCategoria) => {
    history?.push({
      pathname: linkCategoria
    })
  }

  const ListarCategorias = () => {

    let dto = {
      id: 0
    };

    LoadingService.sendLoadingAtivo(true);
    ListarCategoriasCursosService(dto).then(res => {
      LoadingService.sendLoadingAtivo(false);
      if (res.data.error) {
        SnackService.sendMSG(res.data.error);
      } else {
        console.log('categorias', res.data);
        setCategorias(res.data);
      }
    }).catch(err => {
      SnackService.sendMSG('Erro com a conexão ao consultar lista de categorias.');
      LoadingService.sendLoadingAtivo(false);
      console.log(err);
    });
  }

  const CopiarPix = () => {
    navigator.clipboard.writeText('00020126580014BR.GOV.BCB.PIX0136a69a7930-45f2-4316-b55b-5210e2e644175204000053039865802BR5920Allan Pereira Soares6009SAO PAULO61080540900062100506tutors630469C9');
    SnackService.sendMSG('PIX Copiado!');
  }

  return (
    <>
      <header>
        <div className="container">
          <div className="flex flex-sp flex-cv">
            <NavLink exact={true} to="/" activeClassName="active">
              <img src={Logo} alt="Tutors - Cursos gratuitos" />
            </NavLink>

            <nav>
              <ul className="flex flex-sp flex-cv">
                <li>
                  <Button onClick={clickBurger}>
                    <MenuIcon />
                  </Button>
                  <Menu
                    anchorEl={anchorElBurger}
                    open={openMenuBurger}
                    onClose={() => setAnchorElBurger(null)}
                    MenuListProps={{
                      'aria-labelledby': 'basic-button',
                    }}
                  >
                    <ul>
                      <li className={"app-" + isApp}>
                        <MenuItem onClick={() => setModalAtivo(true)}>
                          Pague um café <FreeBreakfastIcon />
                        </MenuItem>
                      </li>
                      <li>
                        <MenuItem onClick={(e: any) => clickCategorias(e)}>
                          Categorias
                        </MenuItem>
                        {/* <NavLink to="#" activeClassName="active">
                          <Button onClick={clickCategorias}>Categorias</Button>
                        </NavLink> */}
                        <Menu
                          anchorEl={anchorElCat}
                          open={openMenuCat}
                          onClose={() => setAnchorElCat(null)}
                          MenuListProps={{
                            'aria-labelledby': 'basic-button',
                          }}
                        >
                          {
                            categorias?.map((categoriaItem, indexCategoria) => (
                              <MenuItem key={indexCategoria} onClick={() => {
                                GoToCategorias('/categorias/' + categoriaItem.slug);
                                setAnchorElCat(null);
                                setAnchorElBurger(null);
                              }}>
                                {categoriaItem.name}
                              </MenuItem>
                            ))
                          }
                        </Menu>
                      </li>
                    </ul>
                  </Menu>
                </li>
                {
                  usuarioLogado ?
                    <>
                      <li className="logado">
                        <Button
                          id="basic-button"
                          onClick={clickIcoUS}
                        >
                          {
                            usuarioLogado.foto ?
                              <i style={{ backgroundImage: 'url(' + usuarioLogado.foto + ')' }}></i>
                              :
                              <AccountCircle />
                          }
                        </Button>
                        <Menu
                          anchorEl={anchorEl}
                          open={openMenuUS}
                          onClose={() => setAnchorEl(null)}
                          MenuListProps={{
                            'aria-labelledby': 'basic-button',
                          }}
                        >
                          <MenuItem className="ola">
                            <span>Olá, {usuarioLogado.display_name}!</span>
                          </MenuItem>
                          <MenuItem onClick={MinhaConta}>Minha conta</MenuItem>
                          <MenuItem onClick={MeusFavoritos}>Meus favoritos</MenuItem>
                          <MenuItem onClick={Sair}>Sair</MenuItem>
                        </Menu>
                      </li>
                    </>
                    :
                    <li>
                      <NavLink to="#" activeClassName="active">
                        <Button onClick={Acessar}>Acessar</Button>
                      </NavLink>
                    </li>
                }
              </ul>
            </nav>
          </div>
        </div>
      </header>
      <div className={"modal-pix flex flex-cv flex-ch ativo-" + modalAtivo}>
        <div className="box-modal">
          <p>
            Gostou da <strong>plataforma Tutors</strong>? Faça uma contribuição através do <strong>PIX</strong> e ajude a manter o projeto, além de nos ajudar a garantir cada vez mais cursos novos e gratuitos.
          </p>
          <p>
            Sua contribuição será direcionada para a manutenção do servidor, desenvolvimento de novas funcionalidades, criação do aplicativo e contratação de serviços e ferramentas necessárias para a produção e divulgação do <strong>projeto Tutors</strong>. E, é claro, uma parte dessa contribuição também será destinada a desfrutar de uma boa xícara de café. 😊
          </p>
          <p>
            <i>Obs. 1: Se você optar por não doar, ainda assim amamos você!</i> 😉
          </p>
          <p>
            <i>Obs. 2: Já deve ter percebido, mas o café é uma analogia. O dinheiro arrecadado será usado para garantir a qualidade e crescimento contínuo da <strong>plataforma Tutors</strong>.</i>
          </p>
          <div className="flex flex-ch">
            <img src={qrCode} alt="qr-code" />
          </div>
          <div className="flex flex-ch">
            <Button className="bt copy-pix" onClick={CopiarPix}>Copiar código QR Code <ContentCopyIcon /></Button>
          </div>
        </div>
        <Button className="fechar" onClick={() => setModalAtivo(false)}>
          <CloseIcon />
        </Button>
      </div>
    </>
  );
}

export default HeaderComponent;
