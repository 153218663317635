import Routes from './routes';
import './App.scss';

function App() {
  return (
    <Routes />
  );
}

export default App;
