import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import CursoModel from '../../models/curso.model';
import { CursoService } from '../../services/api-cursos.service';
import { LoadingService } from '../../services/loading.service';
import { SnackService } from '../../services/snack.service';
import YouTube, { YouTubeProps } from 'react-youtube';
import './curso.page.scss';
import { GetProgressVideosService, InsereProgressVideosService } from '../../services/api-progress-videos.service';
import { UsuarioEfetuouLoginService } from '../../services/usuario-efetuou-login.service';
import UsuarioModel from '../../models/usuario.model';
import { setTimeout } from 'timers';
import ComentaiosComponent from '../../components/comentarios/comentarios.component';
import VideoModel from '../../models/video.model';
import { Button, Rating } from '@mui/material';
import { GetAvaliacaoUsuarioService, ListarAvaliacoesService, SalvarAvaliacaoService } from '../../services/api-avaliacao.service';
import AvaliacaoModel from '../../models/avaliacao.model';
import ProgressVideoModel from '../../models/progress.model';
import { CartaoVirtualService } from '../../services/cartao-virtual.service';
import { GetUserCartaoVirtualService } from '../../services/api-usuario.service';
import { AccountCircle } from "@mui/icons-material";

function CursoPage() {

  let { AliasCurso } = useParams<{ AliasCurso: string }>();
  let history = useHistory();

  const [itemCurso, setItemCurso] = React.useState<CursoModel>();
  const [indexVideo, setIndexVideo] = React.useState<number>(0);
  const [videoAtual, setVideoAtual] = React.useState<string>();
  const [descricaoVideo, setDescricaoVideo] = React.useState<string>();
  const [progressVideos, setProgressVideos] = React.useState<number[]>([]);
  let usLogado: any = localStorage.getItem("user");
  usLogado = JSON.parse(usLogado);
  const [usuarioLogado, setUsuarioLogado] = React.useState<any>(usLogado);
  const [avaliacao, setAvaliacao] = React.useState<number | null>(0);
  const [avaliacaoUsuario, setAvaliacaoUsuario] = React.useState<number | null>(null);
  const [avaliacaoUsuarioDB, setAvaliacaoUsuarioDB] = React.useState<number | null>(null);
  const [totalAvaliacoes, setTotalAvaliacoes] = React.useState<number>(0);
  const [abrirBoxAvaliar, setAbrirBoxAvaliar] = React.useState<boolean>(false);
  const [abrirBoxListAvaliacoes, setAbrirBoxListAvaliacoes] = React.useState<boolean>(false);
  const [listAvaliacoes, setListAvaliacoes] = React.useState<AvaliacaoModel[]>([]);

  const [articleStructuredData, setArticleStructuredData] = React.useState<any>();

  useEffect(() => {
    GetCurso();
  }, []);

  useEffect(() => {
    setArticleStructuredData({
      "@context": "https://schema.org",
      "@type": "Course",
      "name": itemCurso?.titulo,
      "description": itemCurso?.descricao,
      "image": itemCurso?.thumb,
      //datePublished: props.item.it_data,
      "provider": {
        "@type": "Organization",
        "name": "Tutors - Cursos gratuitos",
        "sameAs": "https://tutors.a7apps.com",
      },
    })
  }, [
    videoAtual,
    totalAvaliacoes,
    listAvaliacoes,
    avaliacaoUsuario,
    avaliacaoUsuarioDB
  ]);

  useEffect(() => {
    GetProgressVideos();
    GetAvaliacaoUsuario();
    ListarAvaliacao();
  }, [itemCurso]);

  useEffect(() => {
    //console.log(progressVideos + ' progressVideos');
  }, [progressVideos]);

  useEffect(() => {
    UsuarioEfetuouLoginService.getMSG().subscribe((usuario: any) => {
      setUsuarioLogado(usuario);
    });

    let usLogado = localStorage.getItem("user");
    if (usLogado) {
      setUsuarioLogado(JSON.parse(usLogado));
    }
  }, []);

  const GetCurso = () => {
    LoadingService.sendLoadingAtivo(true);
    CursoService({ alias: AliasCurso }).then(res => {
      LoadingService.sendLoadingAtivo(false);
      if (res.data.error) {
        SnackService.sendMSG(res.data.error);
      } else {
        MontaArrVideos(res.data[0].videos);
        setItemCurso(res.data[0]);
        setIndexVideo(0);
        setVideoAtual(res.data[0].videos[0].id_youtube);
        setDescricaoVideo(res.data[0].videos[0].descricao);
        setTimeout(() => {
          SetMetaHeader(res.data[0]);
        }, 200);
      }
    }).catch(err => {
      SnackService.sendMSG('Erro com a conexão ao consultar frase na base de dados.');
      LoadingService.sendLoadingAtivo(false);
      console.log(err);
    });
  }

  const SetMetaHeader = (itemCursoHeader: CursoModel) => {
    let metaTagKeywords = document.querySelector('meta[name="keywords"]') as HTMLInputElement | null;
    let metaTagDescription = document.querySelector('meta[name="description"]') as HTMLInputElement | null;

    if (itemCursoHeader.keywords && itemCursoHeader.description) {
      metaTagKeywords?.setAttribute("content", itemCursoHeader.keywords);
      metaTagDescription?.setAttribute("content", itemCursoHeader.description);
    }
  }

  const MontaArrVideos = (videos: any) => {
    let newArr: number[] = [];
    videos.map(() => {
      newArr.push(0);
    });
    setProgressVideos(newArr);
  }

  const InsereProgressVideos = (newProgress) => {
    if (!usuarioLogado) {
      SnackService.sendMSG('Não foi possível registrar o progresso da aula, efetue o login clicando em "ACESSAR"!');
      return;
    }

    let dto: ProgressVideoModel = {
      curso_id: itemCurso?.id,
      curso_title: itemCurso?.titulo,
      user_id: usuarioLogado.ID,
      progress_videos: JSON.stringify(newProgress)
    };

    //LoadingService.sendLoadingAtivo(true);
    InsereProgressVideosService(dto).then(res => {
      //LoadingService.sendLoadingAtivo(false);
      if (res.data.error) {
        SnackService.sendMSG(res.data.error);
      } else {
        // setItemCurso(res.data[0]);
        // setIndexVideo(0);
        // setVideoAtual(res.data[0].videos[0].id_youtube);
      }
    }).catch(err => {
      SnackService.sendMSG('Erro com a conexão ao inserir o progresso da aula na base de dados.');
      //LoadingService.sendLoadingAtivo(false);
      console.log(err);
    });
  }

  const GetProgressVideos = () => {
    if (!usuarioLogado) {
      SnackService.sendMSG('Não foi possível carregar seu progresso da aula, faça o login!');
      return;
    }
    if (!itemCurso) {
      return;
    }

    let dto: ProgressVideoModel = {
      curso_id: itemCurso?.id,
      curso_title: itemCurso?.titulo,
      user_id: usuarioLogado.ID
    };

    LoadingService.sendLoadingAtivo(true);
    GetProgressVideosService(dto).then(res => {
      LoadingService.sendLoadingAtivo(false);
      if (res.data.error) {
        SnackService.sendMSG(res.data.error);
      } else {
        //console.log(res.data[0].progress_videos + ' <<< progress_videos');
        setProgressVideos(JSON.parse(res.data[0].progress_videos));
      }
    }).catch(err => {
      //SnackService.sendMSG('Erro com a conexão ao carregar o progresso da aula na base de dados.');
      LoadingService.sendLoadingAtivo(false);
      console.log(err);
    });
  }

  const SalvarAvaliacao = () => {
    if (!usuarioLogado) {
      SnackService.sendMSG('Não foi possível salvar a avaliação, efetue o login clicando em "ACESSAR"!');
      return;
    }

    if (!avaliacaoUsuario) {
      SnackService.sendMSG('Selecione a avaliação clicando nas estrelas.');
      return;
    }

    let dto: AvaliacaoModel = {
      valor_avaliacao: avaliacaoUsuario,
      curso_id: itemCurso?.id,
      curso_title: itemCurso?.titulo,
      user_id: usuarioLogado.ID,
      first_name: usuarioLogado.first_name,
      foto: usuarioLogado.foto
    };

    LoadingService.sendLoadingAtivo(true);
    SalvarAvaliacaoService(dto).then(res => {
      LoadingService.sendLoadingAtivo(false);
      if (res.data.error) {
        SnackService.sendMSG(res.data.error);
      } else {
        // setItemCurso(res.data[0]);
        // setIndexVideo(0);
        // setVideoAtual(res.data[0].videos[0].id_youtube);
        setAvaliacaoUsuarioDB(avaliacaoUsuario);
        ListarAvaliacao();
        SnackService.sendMSG('Avaliação registrada, muito obrigado!');
      }
    }).catch(err => {
      SnackService.sendMSG('Erro com a conexão ao salvar avaliação.');
      LoadingService.sendLoadingAtivo(false);
      console.log(err);
    });
  }

  const GetAvaliacaoUsuario = () => {
    if (!usuarioLogado || !itemCurso) {
      return;
    }

    let dto: AvaliacaoModel = {
      curso_id: itemCurso?.id,
      curso_title: itemCurso?.titulo,
      user_id: usuarioLogado.ID
    };

    LoadingService.sendLoadingAtivo(true);
    GetAvaliacaoUsuarioService(dto).then(res => {
      LoadingService.sendLoadingAtivo(false);
      if (res.data.error) {
        SnackService.sendMSG(res.data.error);
      } else {
        // setItemCurso(res.data[0]);
        // setIndexVideo(0);
        // setVideoAtual(res.data[0].videos[0].id_youtube);
        let avaliacaoUsDb = parseInt(res.data.valor_avaliacao);
        //console.log(avaliacaoUsDb + ' <<<<<<<<< ava usu');
        setAvaliacaoUsuario(avaliacaoUsDb);
        setAvaliacaoUsuarioDB(avaliacaoUsDb);
        //SnackService.sendMSG('Avaliação registrada, muito obrigado!');
      }
    }).catch(err => {
      SnackService.sendMSG('Erro com a conexão ao consultar avaliação.');
      LoadingService.sendLoadingAtivo(false);
      console.log(err);
    });
  }

  const ListarAvaliacao = () => {
    if (!itemCurso) {
      return;
    }

    let dto = {
      curso_id: itemCurso.id
    };

    LoadingService.sendLoadingAtivo(true);
    ListarAvaliacoesService(dto).then(res => {
      LoadingService.sendLoadingAtivo(false);
      if (res.data.error) {
        SnackService.sendMSG(res.data.error);
      } else {
        setTotalAvaliacoes(res.data.length);
        setListAvaliacoes(res.data);

        let qtdAvaliacoes = res.data.length;
        let somaEstrelas = 0;
        res.data.map((item: AvaliacaoModel, index: number) => {
          let valorAtual = (item.valor_avaliacao ? item.valor_avaliacao : 0).toString();
          somaEstrelas += parseInt(valorAtual);
        });
        let calcAvaliacao = somaEstrelas / qtdAvaliacoes;
        setAvaliacao(calcAvaliacao);
      }
    }).catch(err => {
      SnackService.sendMSG('Erro com a conexão ao consultar lista de avaliações.');
      LoadingService.sendLoadingAtivo(false);
      console.log(err);
    });
  }

  const PlayThisVideo = (itemVideo: VideoModel, indexVideoClicado: number) => {
    setVideoAtual(itemVideo.id_youtube);
    setIndexVideo(indexVideoClicado);
    setDescricaoVideo(itemVideo.descricao);
  }

  const OnEndVideo = (event: any) => {
    SetProgress(100);
    if (itemCurso && itemCurso.videos) {
      if (itemCurso && itemCurso.videos.length < indexVideo + 1) {
        setVideoAtual(itemCurso?.videos[indexVideo + 1].id_youtube);
        setIndexVideo(indexVideo + 1);
      }
    }
  }

  const onPlayerReady: YouTubeProps['onReady'] = (event) => {
    setTimeout(() => {
      event.target.playVideo();
    }, 500);
  }

  const onPauseVideo = (event: any) => {
    let duration = event.target.getDuration();
    let current = event.target.getCurrentTime();
    let perc = current / duration * 100;
    SetProgress(perc);
  }

  const SetProgress = (perc: number) => {
    const newProgress = progressVideos.map((item, i) => {
      if (indexVideo === i) {
        return item < perc ? perc : item;
      } else {
        return item;
      }
    });

    setProgressVideos(newProgress);
    InsereProgressVideos(newProgress);
  }

  return (
    <>
      <script type="application/ld+json">
        {JSON.stringify(articleStructuredData)}
      </script>
      <section className="curso-page">
        {
          itemCurso ?
            <div className="container">
              <div className="cols flex flex-sp flex-wr">
                <div className="box-txt flex flex-sp flex-wr">
                  <img src={itemCurso.thumb} alt={itemCurso.titulo} />
                  <div className="txt">
                    <h1>{itemCurso.titulo}</h1>
                    <h2>{itemCurso.descricao}</h2>
                    <div className="box-avaliacao flex flex-cv">
                      <Rating
                        name="avaliacao"
                        value={avaliacao}
                        readOnly
                        onChange={(event, newValue) => {
                          setAvaliacao(newValue);
                        }} />
                      <div className="avaliacao-msg">
                        {
                          totalAvaliacoes == 0 ?
                            <b>Seja o primeiro a avaliar este curso!</b> :
                            totalAvaliacoes == 1 ?
                              <Button className='bt-list-avaliacao' onClick={() => setAbrirBoxListAvaliacoes(true)}>1 avaliação</Button> :
                              <Button className='bt-list-avaliacao' onClick={() => setAbrirBoxListAvaliacoes(true)}>{totalAvaliacoes} avaliações de alunos</Button>
                        }
                      </div>
                      <Button className='bt' onClick={() => setAbrirBoxAvaliar(true)}>Avaliar</Button>
                    </div>
                    {
                      abrirBoxAvaliar &&
                      <div className="box-avaliar">
                        <h4>Quantas estrelas este curso merece?</h4>
                        {
                          avaliacaoUsuarioDB ?
                            <h5>(Você já avaliou este curso com {avaliacaoUsuarioDB} {avaliacaoUsuarioDB > 1 ? 'estrelas' : 'estrela'}, gostaria de alterar?)</h5>
                            : <></>
                        }
                        <Rating
                          name="avaliacaoUsuario"
                          value={avaliacaoUsuario}
                          onChange={(event, newValue) => {
                            setAvaliacaoUsuario(newValue);
                          }} />
                        <div className="bts flex flex-cv">
                          <Button className='bt' onClick={SalvarAvaliacao}>Salvar</Button>
                          <Button className='bt borda' onClick={() => setAbrirBoxAvaliar(false)}>Cancelar</Button>
                        </div>
                      </div>
                    }
                    {
                      abrirBoxListAvaliacoes &&
                      <div className="box-list-avaliacoes">
                        {
                          listAvaliacoes ? listAvaliacoes.map((avaliacaoItem, indexAvaliacao) => (
                            <div className="item-avaliacao flex flex-cv" key={indexAvaliacao}
                              onClick={() =>
                                history?.push({
                                  pathname: '/aluno/' + avaliacaoItem.user_id
                                })
                              }>
                              {
                                avaliacaoItem.foto ?
                                  <img src={avaliacaoItem.foto} alt={avaliacaoItem.first_name} />
                                  :
                                  <AccountCircle className='sem-foto' />
                              }
                              <div className="txt">
                                <h3>{avaliacaoItem.first_name}</h3>
                                <Rating
                                  name={"avaliacaoUsuario-" + avaliacaoItem.user_id}
                                  value={avaliacaoItem.valor_avaliacao}
                                  readOnly
                                />
                              </div>
                            </div>
                          )) : <span>Aguarde...</span>
                        }

                        <div className="bts flex flex-cv">
                          <Button className='bt' onClick={() => setAbrirBoxListAvaliacoes(false)}>Fechar</Button>
                        </div>
                      </div>
                    }
                  </div>
                </div>
                <div className="col-video flex flex-sp flex-wr">
                  {
                    videoAtual &&
                    <div className="box-video">
                      <YouTube
                        videoId={videoAtual}
                        id={videoAtual}
                        onEnd={OnEndVideo}
                        onPause={onPauseVideo}
                        onReady={onPlayerReady}
                      />
                    </div>
                  }
                  <div className="menu-videos">
                    <ul>
                      {
                        itemCurso.videos ? itemCurso.videos.map((videoitem, indexItemVideo) => (
                          <li className={'flex flex-cv flex-sp flex-cv ' + (progressVideos[indexItemVideo] === 100 ? 'finalizado ' : ' ') + (indexVideo === indexItemVideo ? 'ativo' : '')} key={indexItemVideo} onClick={() => PlayThisVideo(videoitem, indexItemVideo)}>
                            <i style={{ width: progressVideos[indexItemVideo] + '%' }}></i>
                            <span className='flex flex-cv flex-ch'>{indexItemVideo + 1}</span>
                            <strong>{videoitem.titulo}</strong>
                          </li>
                        )) : <span>Aguarde...</span>
                      }
                    </ul>
                  </div>
                </div>
                {
                  descricaoVideo &&
                  <div className="desc-video">
                    <p dangerouslySetInnerHTML={{ __html: descricaoVideo }}></p>
                  </div>
                }
                <div className="box-comentarios col-md-4">
                  <ComentaiosComponent idCurso={itemCurso.id} palavrasModeradasADM={['bobo', 'bobão']} />
                </div>
              </div>
            </div>
            :
            <div className="container">
              <p>Aguarde...</p>
            </div>
        }
      </section>
    </>
  );
}

export default CursoPage;
