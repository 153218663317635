import React from 'react';
import './modal-confirm.component.scss';
import { Dialog, DialogTitle, List, ListItem, ListItemButton, ListItemText } from '@mui/material';

const emails = ['Sim', 'Não'];

export interface ModalConfirmComponentProps {
  open: boolean;
  selectedValue: string;
  onClose: (value: string) => void;
}

function ModalConfirmComponent(props: ModalConfirmComponentProps) {
  const { onClose, selectedValue, open } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (value: string) => {
    onClose(value);
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>Tem certeza que deseja excluir sua conta?</DialogTitle>
      <List sx={{ pt: 0 }}>
        {emails.map((email) => (
          <ListItem disableGutters key={email}>
            <ListItemButton onClick={() => handleListItemClick(email)}>
              <ListItemText primary={email} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Dialog>
  );
}

export default ModalConfirmComponent;
