import axios from "axios";
import { environment } from "../environments/environment";
import any from "../models/usuario.model";

export const api = axios.create({
    baseURL: environment.serverUrl
});

export async function LoginService(dto: any) {
    let url = '/login-usuario';
    return await api.post(url, dto, {});
}

export async function CadastrarService(dto: any) {
    let url = '/cadastrar-usuario';
    return await api.post(url, dto, {});
}

export async function AtualizarUsuarioService(dto: any) {
    let url = '/atualizar-usuario';
    return await api.post(url, dto, {});
}

export async function EsqueciMinhaSenhaService(dto: any) {
    let url = '/esqueci-minha-senha';
    return await api.post(url, dto, {});
}

export async function GetUserCartaoVirtualService(dto: any) {
    let url = '/cartao-virtual';
    return await api.post(url, dto, {});
}

export async function GetAlunoService(dto: { idAluno: string }) {
    let url = '/get-aluno';
    return await api.post(url, dto, {});
}

export async function DeleteUsuarioService(dto: { user_id: string }) {
    let url = '/delete-user';
    return await api.post(url, dto, {});
}

export async function SalvaTokenService(dto: { user_id: any, user_email: any, token: any }) {
    let url = '/salva-expopushtoken';
    return await api.post(url, dto, {});
}