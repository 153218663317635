import UsuarioModel from "../models/usuario.model";

export const getAuthUser = (): UsuarioModel => {
    let userOBJ: any = localStorage.getItem('user');
    userOBJ = JSON.parse(userOBJ);
    return userOBJ;
}

export const isAuhenticated = (): boolean => {
    let loginOBJ: any = localStorage.getItem('user');
    loginOBJ = JSON.parse(loginOBJ);
    let isAuth = loginOBJ ? true : false;
    return isAuth;
}