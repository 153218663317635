import { Button } from '@mui/material';
import React from 'react';
import { useHistory } from 'react-router-dom';
import CursoModel from '../../models/curso.model';
import VisibilityIcon from '@mui/icons-material/Visibility';
import StarsIcon from '@mui/icons-material/Stars';
import './card-curso.component.scss';
import { getAuthUser } from '../../services/auth';
import UsuarioModel from '../../models/usuario.model';
import { SnackService } from '../../services/snack.service';
import { LoadingService } from '../../services/loading.service';
import { SalvarFavoritoService } from '../../services/api-favoritos.service';
import FavoritoModel from '../../models/favorito.model';

export type CardCursoComponentProps = {
  itemCurso: CursoModel;
  usuario: UsuarioModel;
}

function CardCursoComponent(props: CardCursoComponentProps) {

  let history = useHistory();

  const AcessarCurso = (alias: string) => {
    history?.push({
      pathname: `/cursos-gratuitos/${alias}`
    })
  }

  const FavoritarCurso = (curso: CursoModel) => {
    let usuario: UsuarioModel = getAuthUser();

    if (!usuario) {
      SnackService.sendMSG('Faça login para favoritar cursos.');
      return;
    }

    LoadingService.sendLoadingAtivo(true);
    let dto: FavoritoModel = {
      id_usuario: usuario.ID,
      id: 0,
      id_curso: curso.id,
      titulo_curso: curso.titulo,
      thumb: curso.thumb,
      breve_descricao: curso.breve_descricao,
      url_curso: curso.alias
    }
    SalvarFavoritoService(dto).then(res => {
      LoadingService.sendLoadingAtivo(false);
      if (res.data.error) {
        SnackService.sendMSG(res.data.error);
      } else {
        SnackService.sendMSG(res.data);
      }
    }).catch(err => {
      SnackService.sendMSG('Erro com a conexão ao consultar frase na base de dados.');
      LoadingService.sendLoadingAtivo(false);
      console.log(err);
    });
  }

  return (
    <>
      <div className="card-cursos-gratuitos">
        <img src={props.itemCurso.thumb} alt={props.itemCurso.titulo} />
        <div className="txt">
          <h3>{props.itemCurso.titulo}</h3>
          <h4>{props.itemCurso.breve_descricao}</h4>
          <div className="bts">
            <Button className='bt' onClick={() => AcessarCurso(props.itemCurso.alias)}>
              <VisibilityIcon />
              Acessar curso
            </Button>
            <Button className='bt favorito' onClick={() => FavoritarCurso(props.itemCurso)}>
              <StarsIcon />
              Favoritar curso
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}

export default CardCursoComponent;
