import { Button, TextField } from '@mui/material';
import React from 'react';
import { useInput } from '../../hooks/useInput.hook';
import UsuarioModel from '../../models/usuario.model';
import { EsqueciMinhaSenhaService } from '../../services/api-usuario.service';
import { LoadingService } from '../../services/loading.service';
import { SnackService } from '../../services/snack.service';
import './esqueci-minha-senha.component.scss';

export function EsqueciMinhaSenhaComponent() {

  const { value: emailInputText, bind: bindEmailInputText, setValue: setEmailInputText } = useInput('');

  var dto: any = {};

  const EsqueciMinhaSenha = () => {

    if (!emailInputText) {
      SnackService.sendMSG('O campo e-mail é obrigatório');
      return;
    }
    
    dto = {
      user_email: emailInputText,
    };

    LoadingService.sendLoadingAtivo(true);

    EsqueciMinhaSenhaService(dto).then((res) => {
      LoadingService.sendLoadingAtivo(false);
      if (res.data?.error) {
        SnackService.sendMSG(res.data?.error);
      } else {
        if (res.data?.error) {
          SnackService.sendMSG(res.data?.error);
        } else {
          SnackService.sendMSG(res.data?.msg_sucesso);
        }
      }
    }).catch(err => {
      LoadingService.sendLoadingAtivo(false);
      SnackService.sendMSG('Erro com a conexão.');
      console.log(err);
    });
  }

  return (
    <div className="esqueci-minha-senha-component">
      <h3>Você receberá um e-mail com a nova senha.</h3>
      <h4></h4>
      <TextField {...bindEmailInputText} label="E-mail" />
      <Button onClick={EsqueciMinhaSenha} className="bt">Obter nova senha</Button>
    </div>
  );
}
