import React, { useEffect } from 'react';
import { Button, Dialog } from '@mui/material';
import { ModalAcessoService } from '../../services/modal-acesso.service';
import LoginComponent from '../login/login.component';
import CadastroComponent from '../cadastro/cadastro.component';
import { EsqueciMinhaSenhaComponent } from '../esqueci-minha-senha/esqueci-minha-senha.component';

import './modal-acesso.component.scss';

function ModalAcessoComponent() {

  const [openModal, setOpenModal] = React.useState<boolean>(false);
  const [showBox, setShowBox] = React.useState<string>('login');

  useEffect(() => {
    ModalAcessoService.getMSG().subscribe((msg: any) => {
      setOpenModal(msg);
    });
  }, []);

  const closeModal = () => {
    setOpenModal(false);
  };

  return (
    <Dialog
      open={openModal}
      onClose={closeModal}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <div className="box-login">
        {
          showBox === 'login' &&
          <div className="box">
            <LoginComponent />
            <Button onClick={() => { setShowBox('cadastro') }}>Cadastre-se</Button>
            <Button onClick={() => { setShowBox('esqueci-minha-senha') }}>Esqueci minha senha</Button>
          </div>
        }
        {
          showBox === 'cadastro' &&
          <div className="box">
            <CadastroComponent />
            <Button onClick={() => { setShowBox('login') }}>Efetuar login</Button>
          </div>
        }
        {
          showBox === 'esqueci-minha-senha' &&
          <div className="box">
            <EsqueciMinhaSenhaComponent />
            <Button onClick={() => { setShowBox('login') }}>Efetuar login</Button>
            <Button onClick={() => { setShowBox('cadastro') }}>Cadastre-se</Button>
          </div>
        }
      </div>
    </Dialog>
  );
}

export default ModalAcessoComponent;
