import axios from "axios";
import { environment } from "../environments/environment";
import AvaliacaoModel from "../models/avaliacao.model";
import UsuarioModel from "../models/usuario.model";

export const api = axios.create({
    baseURL: environment.serverUrl
});

export async function SalvarAvaliacaoService(dto: AvaliacaoModel) {
    let url = '/salvar-avaliacao';
    return await api.post(url, dto, {});
}

export async function GetAvaliacaoUsuarioService(dto: AvaliacaoModel) {
    let url = '/get-avaliacao-usuario';
    return await api.post(url, dto, {});
}

export async function ListarAvaliacoesService(dto: { curso_id: number }) {
    let url = '/listar-avaliacoes';
    return await api.post(url, dto, {});
}
